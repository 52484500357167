import { createTheme } from "@mui/material/styles";

export enum Themes {
  LIGHT_THEME = "Light",
  DARK_THEME = "Dark",
}

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#0cb4b2",
    },
    text: {
      primary: "#000000DE",
    },
    error: {
      main: "#D32F2F",
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#0cb4b2",
    },
  },
});