import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Box, Container, CssBaseline, Typography } from '@mui/material';

function App() {
  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
          Under Construction ...
        </Typography>
      </Box>
    </Container>
  );
}

export default App;
