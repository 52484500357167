import { createSlice } from "@reduxjs/toolkit";

const THEME_MODE = 'THEME_MODE';

export enum THEME_MODES {
    DARK = 'dark',
    LIGHT = 'light'
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    mode: localStorage.getItem(THEME_MODE) || THEME_MODES.LIGHT,
  },
  reducers: {
    setTheme: (state, action) => {

      if (!Object.values(THEME_MODES).includes(action.payload)) {
        return;
      }

      state.mode = action.payload;
      localStorage.setItem(THEME_MODE, action.payload);
    },
    clearTheme: (state) => {
      state.mode = 'dark';
      localStorage.removeItem(THEME_MODE);
    }
  },
});

export const { setTheme, clearTheme } = themeSlice.actions;
export const selectTheme = (state: any) => state.theme.mode;


export default themeSlice.reducer;