import React, { useEffect, useState } from 'react';
import { CssBaseline } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { THEME_MODES, selectTheme } from './app/theme/theme.slice';
import { darkTheme, lightTheme } from './app/theme/theme';
import App from './App';

export const Base = () => {
  const themeMode = useSelector(selectTheme);
  console.log('themeMode', themeMode);
  const [theme, setTheme] = useState<Theme>(lightTheme);

  useEffect(() => {
    if (themeMode === THEME_MODES.LIGHT) {
      setTheme(lightTheme);
    } else {
      setTheme(darkTheme);
    }
  }, [themeMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  );
}