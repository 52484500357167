import { configureStore } from "@reduxjs/toolkit";
import themeSlice from "./theme/theme.slice";

const store = configureStore({
  reducer: {
    theme: themeSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: true,
      immutableCheck: true,
      thunk: true,
    }),
    devTools: process.env.NODE_ENV !== "production",
    preloadedState: {}
});

export default store;